<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'AgendasList',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable'),
    Datepicker: () => import('@/components/general/Datepicker'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Pagination: () => import('@/components/general/Pagination'),
    FilterList: () => import('@/components/general/FilterList'),
    InputField: () => import('@/components/general/InputField'),
    Modal: () => import('@/components/general/Modal'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    SelectField: () => import('@/components/general/SelectField'),
    Action: () => import('@/components/general/Action')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          start_date: 'asc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      modalDeactivateOpen: false,
      modalAgendasFiltersOpen: false,
      agendaId: null,
      agendasList: [],

      filterLabels: {
        active: [
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.1'),
            value: 'true'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.2'),
            value: 'false'
          }
        ],

        modality: [
          {
            text: this.$t('agendas.manage:filtered-form.input.2.option.1'),
            value: 'online'
          },
          {
            text: this.$t('agendas.manage:filtered-form.input.2.option.2'),
            value: 'presencial'
          }
        ]
      },

      tabLinks: [
        {
          location: { name: 'events.index' },
          text: this.$t('events.tabs:link.1')
        },
        {
          location: { name: 'events.agendas.index' },
          text: this.$t('events.tabs:link.2')
        }
      ]
    }
  },

  computed: {
    // ...mapGetters([ 'getAgendaPagination' ]),

    canWrite () {
      return this.getContextPermission('events') === 'write'
    },

    countFilters () {
      return Object.values(this.pagination.filter).filter(item => item !== null && item !== '' && item !== undefined).length
    }
  },

  watch: {
    'pagination.page' () {
      if (this.pagination.actualPage !== this.pagination.page) {
        this.getAgendaList()
      }
    }
  },

  created () {
    // this.pagination = this.getAgendaPagination
    this.getAgendaList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      // 'setAgendaPagination',
      'attemptGetAgendasList',
      'attemptManageAgenda',
      'attemptToggleActiveAgenda'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    doSearch () {
      return this.getAgendaPagination && this.getAgendaPagination.query ? this.getAgendaPagination.query.name : null
    },

    searchAgenda (search) {
      this.pagination.query = {
        name: search,
        only_name: true
      }

      if (this.pagination.page === 1) {
        this.getAgendaList()
      } else {
        this.pagination.page = 1
      }
    },

    edit (agendaId, tab = 1) {
      this.setFetching(true)

      this.attemptManageAgenda(agendaId).then(() => {
        this.$router.push({
          name: 'events.agendas.manage',
          params: {
            id: agendaId,
            tab: tab
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    createNewAgenda () {
      this.attemptManageAgenda(null)
      this.$router.push({ name: 'events.agendas.manage' })
    },

    getAgendaList () {
      this.setFetching(true)
      // this.setAgendaPagination(this.pagination)

      this.attemptGetAgendasList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
        this.pagination.actualPage = pagination.actualPage
        this.agendasList = pagination.data
      }).finally(() => {
        this.setFetching(false)
      })
    },

    toggleActive (agendaId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveAgenda(agendaId).then(() => {
        this.getAgendaList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('agendas:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('agendas:feedback.toggle.deactivate.success') })
        }
      }).catch((e) => {
        if (e.data.error.message === 'agenda__agenda_has_active_schedules') {
          this.setFeedback({ message: this.$t('agendas:feedback.schedules.active') })
        } else if (e.data.error.message === 'event__end_date_passed') {
          this.setFeedback({ message: this.$t('agendas:feedback.schedules.passed') })
        } else {
          this.setFeedback({ message: this.$t('global.error') })
        }
      }).finally(() => {
        this.setFetching(false)
        this.modalDeactivateOpen = false
      })
    },

    deactivate () {
      this.toggleActive(this.eventId, true)
    },

    confirmDeactivate (idEvent) {
      this.eventId = idEvent
      this.modalDeactivateOpen = true
    },

    cancelDeactivate () {
      this.modalDeactivateOpen = false
    },

    getFormattedDate (date) {
      const formattedDate = format(
        new Date(date),
        'dd/MM/yyyy HH:mm'
      )

      return formattedDate
    },

    openFilterModal () {
      this.modalAgendasFiltersOpen = true
    },

    closeFilterModal () {
      this.modalAgendasFiltersOpen = false
    },

    clearFilters () {
      Object.keys(this.pagination.filter).map(key => {
        this.pagination.filter[key] = undefined

        return key
      })
    },

    filter () {
      if (this.pagination.filter && this.pagination.filter.startdate) {
        this.pagination.filter.startdate = this.pagination.filter.startdate + ' 00:00:00'
      }

      if (this.pagination.filter && this.pagination.filter.enddate) {
        this.pagination.filter.enddate = this.pagination.filter.enddate + ' 23:59:59'
      }

      this.closeFilterModal()
      this.getAgendaList()
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('events:header.title')"
      :description="$t('events:header.description')"
      background="/assets/images/themes/default/png/events_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :index-active="1"
        :links="tabLinks"
      />
    </ContentHeader>

    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('agendas.list:btn.add')"
        @click="createNewAgenda"
      />

      <Action
        slot="button"
        flat
        :notify-number="countFilters"
        fixed-width
        type="button"
        :text="$t('agendas.list:btn.filter')"
        @click="openFilterModal"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        :do-search="doSearch()"
        @search="searchAgenda"
      />
    </FilterList>

    <div
      v-if="agendasList && agendasList.length > 0"
      class="center agendas-list"
    >
      <Datatable :items="agendasList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('agendas.list:datatable.header.1') }}
            </th>

            <th class="th text-center">
              {{ $t('agendas.list:datatable.header.2') }}
            </th>

            <th class="th text-center">
              {{ $t('agendas.list:datatable.header.3') }}
            </th>

            <th class="th text-center">
              {{ $t('agendas.list:datatable.header.4') }}
            </th>

            <th class="th text-center">
              {{ $t('agendas.list:datatable.header.5') }}
            </th>

            <th
              v-if="canWrite"
              class="th"
              width="40"
            />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder td-title">
                {{ props.item.name }}<br>
                <span class="event">{{ props.item.event_name || '-' }}</span>
              </span>
            </td>

            <td
              class="td text-center"
              width="140"
            >
              <span class="td-text bolder">
                {{ props.item.modality }}
              </span>
            </td>

            <td
              class="td text-center"
              width="160"
            >
              <span class="td-text bolder td-date">
                {{ getFormattedDate(props.item.start_date) }}<br>
                {{ getFormattedDate(props.item.end_date) }}
              </span>
            </td>

            <td
              class="td text-center"
              width="140"
            >
              <span class="td-text bolder">
                {{ props.item.locality || '-' }}
              </span>
            </td>

            <td
              class="td text-center"
              width="100"
            >
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>

              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>

            <td
              v-if="canWrite"
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id_event_schedule, 1)"
                />

                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id_event_schedule, props.item.active)"
                />

                <DropdownLink
                  v-if="props.item.active"
                  :text="$t('global:deactivate')"
                  @click="confirmDeactivate(props.item.id_event_schedule)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>

      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage(pagination)"
        @lastPage="lastPage(pagination)"
        @nextPage="nextPage(pagination)"
        @previousPage="prevPage(pagination)"
      />
    </div>

    <div
      v-else
      class="center agendas-table"
    >
      <p>Nenhuma agenda encontrada para o termo pesquisado</p>
    </div>

    <ModalConfirm
      :active="modalDeactivateOpen"
      :title="$t('agendas.manage:deactivate.title')"
      :description="$t('agendas.manage:deactivate.description')"
      @confirmAction="deactivate"
      @cancelAction="cancelDeactivate"
    />

    <Modal
      class="modal-agendas-filters"
      :active="modalAgendasFiltersOpen"
      :cancel="true"
      @close="closeFilterModal"
    >
      <div class="modal-form modal-agendas-filters">
        <h2 class="modal-title">
          {{ $t('agendas.list:filter.title') }}
        </h2>

        <span class="modal-subtitle">{{ $t('agendas.list:filter.description') }}</span>

        <form @submit.prevent="filter">
          <SelectField
            v-model="pagination.filter.active"
            class="filter-agendas-field"
            dark
            :label="$t('agendas.manage:filtered-form.input.1')"
            :items="filterLabels.active"
          />

          <SelectField
            v-model="pagination.filter.modality"
            class="filter-agendas-field"
            dark
            :label="$t('agendas.manage:filtered-form.input.2')"
            :items="filterLabels.modality"
          />

          <InputField
            v-model="pagination.filter.locality"
            dark
            :label="$t('global:form.agendas.local')"
            :counter="120"
          />

          <Datepicker
            v-model="pagination.filter.startdate"
            dark
            class="agendas-date"
            hide-optional-text
            :label="$t('agendas.list:start.date')"
            min="1900-01-01"
          />

          <Datepicker
            v-model="pagination.filter.enddate"
            dark
            class="agendas-date"
            hide-optional-text
            :label="$t('agendas.list:end.date')"
            min="1900-01-01"
          />

          <div class="form-buttons">
            <Action
              primary
              large
              dark
              fixed-width
              type="button"
              :text="$t('agendas.list:btn.clear.filter.action')"
              @click="clearFilters"
            />

            <Action
              secondary
              large
              dark
              submit
              fixed-width
              type="button"
              :text="$t('agendas.list:btn.filter.action')"
            />
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<style lang="scss">
.agendas-list {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;

  .td-title {
    .event {
      font-size: 13px;
      line-height: 24px;
      color: rgba(0,0,0,0.5);
      font-weight: 500;
    }
  }

  .td-date {
    text-align: right;
    font-size: 14px;
    line-height: 24px;
  }
}
.agendas-config h3 {
  color: rgba(0,0,0,0.5);
  font-family: 'Quicksand';
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.2em;
  height: auto;
}
.agendas-config span {
  font-size: 1.25em;
}
.agendas-config .datatable-wrapper {
  padding-top: 35px;
}
.agendas-config table .td {
  border: none;
}
.td.input-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}
.input-range .form-input{
  padding: 8px 0;
}
.td .form-item.has-value.has-floating-label .form-label {
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 500;
}
.agendas-config .btn{
  align-self: center;
  margin-top: 15px;
}

.modal-agendas-filters .form-buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
</style>
